
@import "igniteui-angular/lib/core/styles/themes/index";


@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import './assets/fonts/font-awesome-pro/all.min.css';
@import '@core/scss/angular/libs/select.component.scss';
@import '@core/scss/angular/libs/datatables.component.scss';
@import '@core/scss/angular/libs/file-uploader.component.scss';

@import '@core/scss/base/pages/app-calendar.scss';
@import '@core/scss/angular/libs/flatpickr.component.scss';
@import '@core/scss/angular/libs/select.component.scss';

@import '@core/scss/base/bootstrap-extended/include.scss'; // Bootstrap includes
// @import '@fullcalendar/daygrid/main.css';

@import '@core/scss/angular/libs/quill-editor.component.scss';
@import '@core/scss/angular/libs/form-wizard.component.scss';
@import '@core/scss/angular/libs/select.component.scss';
//commented by Yashraj 04-05-2022


:root {
  --font-family-sans-serif: 'Roboto', sans-serif;
  --font-family-monospace: 'Roboto', sans-serif;
  --primery-color: #1498C7;
}

html, body{font-family: 'Roboto', sans-serif;}
.pt-8{
  padding-top: 8px !important;
}
html .content .content-wrapper .content-header-title{line-height: 1;color: #504B61 !important;}

// Progress circle CSS Start

.progress-circle[data-progress="0"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(90deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8);}
.progress-circle[data-progress="1"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(93.6deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="2"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(97.2deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="3"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(100.8deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="4"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(104.4deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="5"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(108deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="6"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(111.6deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="7"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(115.2deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="8"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(118.8deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="9"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(122.4deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="10"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(126deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="11"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(129.6deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="12"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(133.2deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="13"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(136.8deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="14"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(140.4deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="15"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(144deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="16"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(147.6deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="17"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(151.2deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="18"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(154.8deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="19"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(158.4deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="20"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(162deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="21"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(165.60000000000002deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="22"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(169.2deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="23"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(172.8deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="24"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(176.4deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="25"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(180deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="26"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(183.60000000000002deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="27"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(187.2deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="28"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(190.8deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="29"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(194.4deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="30"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(198deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="31"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(201.60000000000002deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="32"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(205.2deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="33"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(208.8deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="34"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(212.4deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="35"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(216deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="36"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(219.6deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="37"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(223.20000000000002deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="38"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(226.8deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="39"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(230.4deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="40"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(234deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="41"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(237.6deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="42"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(241.20000000000002deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="43"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(244.8deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="44"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(248.4deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="45"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(252deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="46"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(255.6deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="47"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(259.20000000000005deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="48"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(262.8deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="49"]:after {background-image: linear-gradient(90deg, #D8D8D8 50%, transparent 50%, transparent), linear-gradient(266.4deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="50"]:after {background-image: linear-gradient(-90deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8);}
.progress-circle[data-progress="51"]:after {background-image: linear-gradient(-86.4deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="52"]:after {background-image: linear-gradient(-82.8deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="53"]:after {background-image: linear-gradient(-79.2deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="54"]:after {background-image: linear-gradient(-75.6deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="55"]:after {background-image: linear-gradient(-72deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="56"]:after {background-image: linear-gradient(-68.4deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="57"]:after {background-image: linear-gradient(-64.8deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="58"]:after {background-image: linear-gradient(-61.2deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="59"]:after {background-image: linear-gradient(-57.6deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="60"]:after {background-image: linear-gradient(-54deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="61"]:after {background-image: linear-gradient(-50.4deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="62"]:after {background-image: linear-gradient(-46.8deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="63"]:after {background-image: linear-gradient(-43.199999999999996deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="64"]:after {background-image: linear-gradient(-39.6deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="65"]:after {background-image: linear-gradient(-36deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="66"]:after {background-image: linear-gradient(-32.4deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="67"]:after {background-image: linear-gradient(-28.799999999999997deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="68"]:after {background-image: linear-gradient(-25.200000000000003deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="69"]:after {background-image: linear-gradient(-21.599999999999994deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="70"]:after {background-image: linear-gradient(-18deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="71"]:after {background-image: linear-gradient(-14.399999999999991deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="72"]:after {background-image: linear-gradient(-10.799999999999997deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="73"]:after {background-image: linear-gradient(-7.200000000000003deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="74"]:after {background-image: linear-gradient(-3.599999999999994deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="75"]:after {background-image: linear-gradient(0deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="76"]:after {background-image: linear-gradient(3.600000000000009deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="77"]:after {background-image: linear-gradient(7.200000000000003deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="78"]:after {background-image: linear-gradient(10.799999999999997deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="79"]:after {background-image: linear-gradient(14.400000000000006deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="80"]:after {background-image: linear-gradient(18deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="81"]:after {background-image: linear-gradient(21.60000000000001deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="82"]:after {background-image: linear-gradient(25.200000000000003deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="83"]:after {background-image: linear-gradient(28.799999999999997deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="84"]:after {background-image: linear-gradient(32.400000000000006deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="85"]:after {background-image: linear-gradient(36deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="86"]:after {background-image: linear-gradient(39.599999999999994deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="87"]:after {background-image: linear-gradient(43.20000000000002deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="88"]:after {background-image: linear-gradient(46.80000000000001deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="89"]:after {background-image: linear-gradient(50.400000000000006deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="90"]:after {background-image: linear-gradient(54deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="91"]:after {background-image: linear-gradient(57.599999999999994deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="92"]:after {background-image: linear-gradient(61.20000000000002deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="93"]:after {background-image: linear-gradient(64.80000000000001deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="94"]:after {background-image: linear-gradient(68.4deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="95"]:after {background-image: linear-gradient(72deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="96"]:after {background-image: linear-gradient(75.6deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="97"]:after {background-image: linear-gradient(79.20000000000002deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="98"]:after {background-image: linear-gradient(82.80000000000001deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="99"]:after {background-image: linear-gradient(86.4deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle[data-progress="100"]:after {background-image: linear-gradient(90deg, #0295C9 50%, transparent 50%, transparent), linear-gradient(270deg, #0295C9 50%, #D8D8D8 50%, #D8D8D8); }
.progress-circle::after {border-radius: 50%;content: '';position: absolute;display: inline-block;height: 100%;width: 100%;}
.progress-wrapper .pp-circle{width: 150px;height: 150px;position: relative;margin: auto;}
.progress-circle::before {background-color: #fff;left: 10px;right: 10px;top: 10px;bottom: 10px;content: attr(data-progress) '%';font-size: 28px;font-weight: 500;display: flex;justify-content: center;position: absolute;border-radius: 100%;align-items: center;z-index: 2;}

// Progress circle CSS End

/*IGX DATE PICKER CSS*/
igx-date-range-picker .igx-icon{display: block;position: relative;color: transparent;width: 18px !important;height: auto !important;}
igx-date-range-picker .igx-icon::before {content: "\f133";font-family: "Font Awesome 5 Pro";font-weight: 300;position: absolute;left: 0;right: 0;top: 0;bottom: 0;display: block;z-index: 20;color: #323232;}
.igx-input-group--bootstrap .igx-input-group__input, .igx-input-group--bootstrap .igx-input-group__textarea{display: block;width: 100%;height: 2.714rem !important;padding: 0.438rem 1rem !important;font-size: 1rem !important;font-weight: 400;color: #6e6b7b;background-color: #fff;background-clip: padding-box;border: 1px solid #d8d6de !important;}
.igx-input-group--bootstrap igx-prefix, .igx-input-group--bootstrap [igxPrefix]{height: 2.714rem !important;}
.igx-input-group--focused.igx-input-group--bootstrap .igx-input-group__input, .igx-input-group--focused.igx-input-group--bootstrap .igx-input-group__file-input, .igx-input-group--focused.igx-input-group--bootstrap .igx-input-group__textarea{border: 0.0625rem solid #7367f0 !important;box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;}
.igx-calendar__body .igx-calendar-picker__prev, .igx-calendar__body .igx-calendar-picker__next{top: 0.2rem;}
.igx-calendar__body .igx-calendar-picker__prev .igx-icon, .igx-calendar__body .igx-calendar-picker__next .igx-icon{display: block;position: relative;color: transparent;width: 20px !important;height: 20px !important;}
.igx-calendar__body .igx-calendar-picker__prev .igx-icon::before {content: "\f053";font-family: "Font Awesome 5 Pro";font-weight: 300;position: absolute;left: 0;right: 0;top: -3px;bottom: 0;display: block;z-index: 20;color: #323232;font-size: 1.3rem;}
.igx-calendar__body .igx-calendar-picker__next .igx-icon::before {content: "\f054";font-family: "Font Awesome 5 Pro";font-weight: 300;position: absolute;left: 0;right: 0;top: -3px;bottom: 0;display: block;z-index: 20;color: #323232;font-size: 1.3rem;}
.igx-calendar__body .igx-calendar-picker {padding-bottom: 10px;align-items: flex-start;}
.igx-date-picker .igx-calendar__label {color:var(--primary) !important;font-weight: bold;font-size: 0.85rem;font-style: normal;}
.igx-calendar-picker div{font-weight: bold;}
.igx-calendar__date--current .igx-calendar__date-content{background: var(--primary) !important;}
.igx-date-picker .igx-calendar__date:hover .igx-calendar__date-content::after, .igx-date-picker .igx-calendar__date:focus .igx-calendar__date-content::after, .igx-date-picker .igx-calendar__date--weekend:hover .igx-calendar__date-content::after, .igx-date-picker .igx-calendar__date--weekend:focus .igx-calendar__date-content::after{background: var(--primary) !important;}
.igx-date-picker .igx-button {display: inline-block;font-weight: 400;text-align: center;vertical-align: middle;-webkit-user-select: none;-moz-user-select: none;user-select: none;background-color: transparent;border: 1px solid transparent;padding: 0.586rem 0.8rem;font-size: 1rem;line-height: 1;border-radius: 0.358rem;border: 1px solid #7367f0 !important;background-color: transparent;color: #7367f0;margin: 0.25rem;cursor: pointer;transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;min-width: auto;min-height: auto;}
.igx-date-picker #actions{padding: 10px;}
/*IGX DATE PICKER CSS*/

.btn.btn-hs-icon-custom {display: block;padding: 0;height: 2.715rem;width: 2.715rem;font-size: 1.6rem;margin: auto 0 auto auto;}
/*TABLE COMMON CSS*/
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active:hover a{width: auto;min-width: 32px;}
.ngx-datatable .datatable-body-cell .datatable-body-cell-label{width: 99%;}
.ngx-datatable .datatable-body-cell .datatable-body-cell-label, .ngx-datatable .datatable-header-cell .datatable-header-cell-label {max-width: 99%;white-space: wrap;}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:not(:last-child){border-right: 1px solid #e4e1f1;}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell{cursor: all-scroll;user-select: none;}
.ngx-datatable.bootstrap.core-bootstrap .datatable-row-wrapper .datatable-body-row .datatable-body-cell{border-right: 1px solid #e4e1f1;border-bottom: 1px solid #e4e1f1;}
.ngx-datatable.bootstrap.core-bootstrap .datatable-header .datatable-header-cell{padding: 0.55rem 1rem;}
.ngx-datatable.bootstrap.core-bootstrap .datatable-row-wrapper .datatable-body-row .datatable-row-center .datatable-body-cell:last-child{border-right: 0 none;}
// .ngx-datatable.bootstrap.core-bootstrap .datatable-header .datatable-header-cell[title="Action"], .ngx-datatable.bootstrap.core-bootstrap .datatable-header .datatable-header-cell[title="Status"]{max-width: 100px;min-width: 100px;width: 100px;}
datatable-body-cell.columns-status.datatable-body-cell,
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell.columns-status,
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell.columns-action,
.ngx-datatable.bootstrap.core-bootstrap .datatable-row-wrapper .datatable-body-row .datatable-body-cell.columns-action{width: 120px !important;}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell.columns-profile,
.ngx-datatable.bootstrap.core-bootstrap .datatable-row-wrapper .datatable-body-row .datatable-body-cell.columns-profile,
.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell.columns-profile{width: 280px !important; }
.ngx-datatable.fixed-row .datatable-body .datatable-scroll .datatable-row-wrapper .datatable-body-row .datatable-row-center  .columns-status,
.ngx-datatable.fixed-row .datatable-body .datatable-scroll .datatable-row-wrapper .datatable-body-row .datatable-row-center  .columns-action{width: 120px !important;}
.dropdown-menu.hide-show-cols {max-height: 280px;overflow-y: auto;right: 0;left: auto;}
.ngx-datatable .datatable-body-cell .datatable-body-cell-label .ellipsis-text-box, .ngx-datatable .datatable-header-cell .datatable-header-cell-label .ellipsis-text-box{max-height: 44px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; max-width:100%;}
.ngx-datatable.bootstrap.core-bootstrap .datatable-header .datatable-header-cell-template-wrap span.datatable-header-cell-wrapper.ng-star-inserted {width: 100%;text-overflow: ellipsis;overflow: hidden;}
.r-500{font-weight: 500;}

datatable-header-cell.datatable-header-cell.check-all.resizeable {
  padding-left: 37px !important;
}





/*TABLE COMMON CSS*/
.btn.btn-icon.btn-icon-custom {padding: 0.5rem;width: 34px;height: 34px;font-size: 14px;line-height: 1.5;text-align: center;margin-right: 5px;z-index: 2;}
.btn.btn-icon.list-btn-image{
    position: relative;
    overflow: hidden;
    padding: 4px;
}
.btn.btn-icon.list-btn-image span{
  display: block;
  position: relative;
  overflow: hidden;
  height: 26px;
}
.btn.btn-icon.list-btn-image img {
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.swal2-container{z-index: 10000 !important;}
.book-name-text .ellipsis-book-name-text {max-height: 34px;overflow: hidden;font-size: 13px !important;margin-bottom: 0;margin-top: 3px;line-height: 17px;}


.navigation-main li.nav-item.has-sub svg.feather.feather-user {stroke: #304d6d;}
.error-msg{color: red}
.strong-password {background-color: #12CC1A;border: #0FA015 1px solid;font-size: 12px;font-weight: 500;color: #ffffff;margin-top: 2px;padding: 6px 10px;position: absolute;z-index: 99;box-shadow: 1px 2px 10px rgb(0 0 0 / 20%);left: 10px;right: 10px;}
.weak-password {background-color: #FF6600;border: #AA4502 1px solid;font-size: 12px;font-weight: 500;color: #ffffff !important;margin-top: 2px;padding: 6px 10px;position: absolute;z-index: 99;box-shadow: 1px 2px 10px rgb(0 0 0 / 20%);left: 10px;right: 10px;}
.medium-password {background-color: #e7b103;border: #af8605 1px solid;font-size: 12px;font-weight: 500;color: #ffffff !important;margin-top: 2px;padding: 3px;position: absolute;z-index: 99;box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);left: 10px;right: 10px;}

.daterangepicker {position: absolute;color: inherit;background-color: #fff;border-radius: 4px;border: 1px solid #ddd;width: 278px;max-width: none;padding: 0;margin-top: 7px;top: 100px;left: 20px;display: none;font-family: arial;font-size: 15px;line-height: 1em;}
.daterangepicker:before, .daterangepicker:after {position: absolute;display: inline-block;border-bottom-color: rgba(0, 0, 0, 0.2);content: '';}
.daterangepicker:before {top: -7px;border-right: 7px solid transparent;border-left: 7px solid transparent;border-bottom: 7px solid #ccc;}
.daterangepicker:after {top: -6px;border-right: 6px solid transparent;border-bottom: 6px solid #fff;border-left: 6px solid transparent;}
.daterangepicker.opensleft:before {right: 9px;}
.daterangepicker.opensleft:after {right: 10px;}
.daterangepicker.openscenter:before {left: 0;right: 0;width: 0;margin-left: auto;margin-right: auto;}
.daterangepicker.openscenter:after {left: 0;right: 0;width: 0;margin-left: auto;margin-right: auto;}
.daterangepicker.opensright:before {left: 9px;}
.daterangepicker.opensright:after {left: 10px;}
.daterangepicker.drop-up {margin-top: -7px;}
.daterangepicker.drop-up:before {top: initial;bottom: -7px;border-bottom: initial;border-top: 7px solid #ccc;}
.daterangepicker.drop-up:after {top: initial;bottom: -6px;border-bottom: initial;border-top: 6px solid #fff;}
.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {float: none;}
.daterangepicker.single .drp-selected {display: none;}
.daterangepicker.show-calendar .drp-calendar {display: block;}
.daterangepicker.show-calendar .drp-buttons {display: block;}
.daterangepicker.auto-apply .drp-buttons {display: none;}
.daterangepicker .drp-calendar {display: none;max-width: 270px;}
.daterangepicker .drp-calendar.left {padding: 8px 0 8px 8px;}
.daterangepicker .drp-calendar.right {padding: 8px;}
.daterangepicker .drp-calendar.single .calendar-table {border: none;}
.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {color: #fff;border: solid black;border-width: 0 2px 2px 0;border-radius: 0;display: inline-block;padding: 3px;}
.daterangepicker .calendar-table .next span {transform: rotate(-45deg);-webkit-transform: rotate(-45deg);}
.daterangepicker .calendar-table .prev span {transform: rotate(135deg);-webkit-transform: rotate(135deg);}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {white-space: nowrap;text-align: center;vertical-align: middle;min-width: 32px;width: 32px;height: 24px;line-height: 24px;font-size: 12px;border-radius: 4px;border: 1px solid transparent;white-space: nowrap;cursor: pointer;}
.daterangepicker .calendar-table {border: 1px solid #fff;border-radius: 4px;background-color: #fff;}
.daterangepicker .calendar-table table {width: 100%;margin: 0;border-spacing: 0;border-collapse: collapse;}
.daterangepicker td.available:hover, .daterangepicker th.available:hover {background-color: #eee;border-color: transparent;color: inherit;}
.daterangepicker td.week, .daterangepicker th.week {font-size: 80%;color: #ccc;}
.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {background-color: #fff;border-color: transparent;color: #999;}
.daterangepicker td.in-range {background-color: #ebf4f8;border-color: transparent;color: #000;border-radius: 0;}
.daterangepicker td.start-date {border-radius: 4px 0 0 4px;}
.daterangepicker td.end-date {border-radius: 0 4px 4px 0;}
.daterangepicker td.start-date.end-date {border-radius: 4px;}
.daterangepicker td.active, .daterangepicker td.active:hover {background-color: #357ebd;border-color: transparent;color: #fff;}
.daterangepicker th.month {width: auto;}
.daterangepicker td.disabled, .daterangepicker option.disabled {color: #999;cursor: not-allowed;text-decoration: line-through;}
.daterangepicker select.monthselect, .daterangepicker select.yearselect {font-size: 12px;padding: 1px;height: auto;margin: 0;cursor: default;}
.daterangepicker select.monthselect {margin-right: 2%;width: 56%;}
.daterangepicker select.yearselect {width: 40%;}
.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {width: 50px;margin: 0 auto;background: #eee;border: 1px solid #eee;padding: 2px;outline: 0;font-size: 12px;}
.daterangepicker .calendar-time {text-align: center;margin: 4px auto 0 auto;line-height: 30px;position: relative;}
.daterangepicker .calendar-time select.disabled {color: #ccc;cursor: not-allowed;}
.daterangepicker .drp-buttons {clear: both;text-align: right;padding: 8px;border-top: 1px solid #ddd;display: none;line-height: 12px;vertical-align: middle;}
.daterangepicker .drp-selected {display: inline-block;font-size: 12px;padding-right: 8px;}
.daterangepicker .drp-buttons .btn {margin-left: 8px;font-size: 12px;font-weight: bold;padding: 4px 8px;}
.daterangepicker.show-ranges.single.rtl .drp-calendar.left {border-right: 1px solid #ddd;}
.daterangepicker.show-ranges.single.ltr .drp-calendar.left {border-left: 1px solid #ddd;}
.daterangepicker.show-ranges.rtl .drp-calendar.right {border-right: 1px solid #ddd;}
.daterangepicker.show-ranges.ltr .drp-calendar.left {border-left: 1px solid #ddd;}
.daterangepicker .ranges {float: none;text-align: left;margin: 0;}
.daterangepicker.show-calendar .ranges {margin-top: 8px;}
.daterangepicker .ranges ul {list-style: none;margin: 0 auto;padding: 0;width: 100%;}
.daterangepicker .ranges li {font-size: 14px;padding: 8px 12px;cursor: pointer;}
.daterangepicker .ranges li:hover {background-color: #eee;}
.daterangepicker .ranges li.active {background-color: #08c;color: #fff;}
.date-placeholder{color: #bab8c0 !important;}
.ng2-flatpickr-input-container input {padding-right: 30px;width: 100%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;}
input::-webkit-contacts-auto-fill-button {visibility: hidden;display: none !important;pointer-events: none;position: absolute;right: 0;}
button.input-group {border: none;background-color: transparent;padding: 0;}
.container {max-width: 100%;}
.p-relative{position: relative;}
button.input-group {text-align: inherit;}
button.input-group span.uneditable-input{white-space: nowrap;overflow: hidden;}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell{white-space: pre-wrap !important;}
// .container.content-wrapper {padding: 0;}
.custom-file-label{padding: 9px 1rem; white-space: nowrap;}
.custom-file-label:after {padding: 9px 1rem;white-space: nowrap; }
.table-shorting-select select.form-control,
.table-shorting-select ng-select{width: 70px; height: 38px !important;}
.datatable-body-cell-label img.rounded-circle {display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}
.datatable-body-cell-label span.text-muted,.datatable-body-cell-label p.ellipsis-book-name-text{display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}
// course mamagment course master css start
// .search-input-box-with-icon{position: absolute;top: auto;right: 0.9rem;}
.card-header-item-input {margin-right: 5px;margin-top: 10px; width: 100%;}
.card-header-item-input-search{margin-top: 10px; width: 100%;}
.card-header-input-box .card-header-item-input:last-child{margin-right: 0;}
.card-header-input-box {display: flex;flex-wrap: wrap;width: 100%;}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  flex-wrap: nowrap !important;
  overflow: hidden;
}
.tag-margin{margin-left: 5px;margin-bottom: 5px;}
// course mamagment course master css end
// course mamagment expert-master page Start


.add-remove-icons{position: absolute;bottom: 5px;right: 5px;}
button.btn.add-icon,
button.btn.remove-icon {border: none !important;border-radius: 0;font-size: 40px;line-height: 30px;height: 38px;padding: 0px 10px;} 
.domain-field-button-grid{
  display: flex;
    align-items: center;
    grid-gap: 6px;
    position: absolute;
    top: 6px;
    right: 6px;
}
.domain-field-icon {
  position: relative;
  top: 5px;
  right: 10px;
  line-height: 15px;
  border-radius: 50%;
  color: #ffff;
  width: 18px;
  height: 18px;
  text-align: center;
  padding: 2px;
  font-size: 10px;
  cursor: pointer;
  border: 0 none;
}

button.btn.add-icon{font-size: 30px;}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row:last-child{border-bottom: 1px solid #d1d4d7;}

.cstm-email-template table {width: 100% !important;}
.cstm-email-template .modal-body>table>tbody>tr>td>div {position: relative !important;margin-bottom: 20px;left: 0 !important;}

.cstm-email-template .modal-body {word-break: break-all;padding-top: 50px;}
.cstm-email-template .modal-header .modal-title{margin-top: 0.8rem;margin-left: 1rem;}
.cstm-email-template .modal-body table>tbody>tr td p:nth-child(1){margin-right: 10px;}
.cstm-email-template .modal-body table>tbody>tr td>table>tbody>tr td p:nth-child(1),
.cstm-email-template .modal-body table>tbody>tr td>table>tbody>tr td p:nth-child(2){display: inline-block; width:auto;}
.cstm-email-template .modal-body>table>tbody>tr:nth-child(4) td table{text-align: center;}
.empty-row.ng-star-inserted {text-align: center;}
ng-select.form-control{
  padding: 0 !important;
}

ng-select.is-invalid .ng-arrow-wrapper {
  margin-right: 15px;
}

.badge{font-weight: 400;}
.pagination-text {font-size: 14px;font-style: italic;}
// Email Template Css End

// ng select border remove css start
ng2-flatpickr.input-border-none .ng2-flatpickr-input-container,ng2-flatpickr.input-border-none .ng2-flatpickr-input-container input.form-control, .ng-select.input-border-none .ng-select-container{border: 0 !important; background: 0 !important;}
.ng-select.input-border-none.is-invalid .ng-select-container .ng-arrow-wrapper{margin-right: 15px;}
// ng select border remove css end
// image modal design start
app-image-gallery{background-color: #fff;}
ngb-modal-window .modal-body {padding: 0;}
ngb-modal-window ~ .flatpickr-calendar {z-index: 9999 !important;}
ngb-modal-window app-image-gallery .modal-body {padding: 0 20px;}
// image modal design end
// CONTAINER CSS
@media (min-width: 1440px) {
 
}
// CONTAINER CSS

// file upload box

ng-select.is-invalid .ng-arrow-wrapper {margin-right: 15px;}
ng-select.form-control{
  padding: 0 !important;
}
a.ng-star-inserted.plus-icon {
  position: absolute;
  top: 5px;
  right: 11px;
  font-size: 13px;
  line-height: 15px;
  background-color: #1498C7;
  border-radius: 50%;
  color: #ffff;
  padding: 2px 2px;
  width: 18px;
  height: 18px;
  text-align: center;
  z-index: 9;
}


.image-upload-box.form-group>div img {width: auto;max-width: 100%;height: 100%;object-fit: contain;}
.image-upload-box {padding: 10px;border: 1px solid #d8d6de;border-radius: 5px;min-height: 175px;height: calc(100% - 15px);}
.custom-input-border{border: 1px solid #d8d6de;border-radius: 0.357rem;padding-top: 8px;}
.custom-input-border .form-control{background-color: transparent;}
.custom-input-border .form-control, .custom-input-border .input-group-text {border: 1px solid #d8d6de;font-size: 1.4rem;padding-top: 0;height: 2.314rem;padding-bottom: 0.2rem;background-color: transparent;}
.custom-input-border textarea.form-control{height: auto;padding: 0.438rem 1rem !important;background: none;}
.custom-input-border label{padding: 0 1rem; margin: 0;line-height:1.5;display: block;background: transparent;text-transform: capitalize;}
.custom-input-border p{font-size: 12px;margin-bottom: 0;padding: 0 1rem;border-top: 1px solid #d8d6de;}
.custom-input-border textarea{resize: vertical;overflow: hidden;}
.custom-input-border input[type="checkbox"]{margin: 0 5px 10px 15px;}
.custom-input-border .input-group label{font-size: calc(15px + 2 *((100vw - 320px) / 680));}
.custom-input-border input+label{padding-left: 0px;background: transparent;font-size: calc(12px + 2 *((100vw - 320px) / 680)) !important;padding-top: 0px;margin-left: 3px;}
.custom-input-border .custom-file,.custom-file-label:after{height: auto;background: transparent;}
.custom-input-border .custom-file-label:after{font-size: 11px;padding: 6px 10px;color: #000;background: #ffffff;height: 2rem;}
.custom-input-border .form-control,.custom-input-border .input-group-text {border: none;font-size: 1.3rem;padding-top: 0;color: #333333;}
.custom-input-border .custom-control.custom-radio,.custom-input-border .custom-control.custom-checkbox{margin: 5px 6px;padding-left: 20px !important;}

.custom-input-border button.input-group span {border: none;padding-top: 0%;background: transparent;}
.custom-input-border .ng-select .ng-select-container .ng-value-container .ng-placeholder{font-size: 1.3rem;}
.custom-input-border .ng-dropdown-panel-items.scroll-host .ng-option{font-size: 1rem;}

.custom-input-border .input-group-text{padding-bottom: 6px;}
.custom-input-border button, .custom-input-border .ng-select.ng-select-focused{box-shadow: none !important;}
.custom-input-border input.inner-input-border{width: calc(100% - 10px);padding: 8px 10px !important;margin: 0px 5px 7px 5px; border: 1px solid #d8d6de;color:#6E6B7B;border-radius:4px;font-size:calc(12px + 2 *((100vw - 320px) / 680));outline: none;}
.custom-input-border hr {margin: 10px 0px;}
.custom-input-border input:disabled {opacity: 0.5;}
.custom-input-border .ng-select .ng-select-container {
  min-height: auto !important;
  border: 0 none;
  margin-left: 0;
  box-shadow: none;
  height: auto;
  background-color: transparent;
  align-items: center;
}
.custom-input-border .ng-select .ng-select-container .ng-value-container {
 
  height: 2.314rem;
  padding: 0.438rem 1rem 0.6rem 1rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 0.85;
  color: #383838;
  background-color: transparent;
  background-clip: padding-box;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} 
.custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding: 0;}
.custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input input{margin-left: -4px;margin-top: 1px;}
.custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input input{margin-left: 0;}
app-user-form .custom-input-border .ng-select.ng-select-focused .ng-select-container{min-height: 2.314rem !important;}
.custom-input-border .ng-dropdown-panel {width: auto;left: -1px;right: -1px;}

.custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.custom-input-border .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  padding: 4px 6px !important;
  font-weight: 400;

  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 
  font-size: 1.3rem !important;
  padding-bottom: 0.2rem;
  padding-top: 2px;
 
  max-width: 50%;
  min-width: auto;
display: block;
text-overflow: ellipsis;
overflow: hidden;
}
.custom-input-border .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value{width: 100% !important;
  max-width: 100% !important;padding: 0 !important;}
.custom-input-border app-multiple-age-group .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.custom-input-border app-multi-select-tag-search .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.custom-input-border app-multi-select-tags .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
  width: fit-content;
  background-color: #1498C7 !important;
  -webkit-text-fill-color: #fff !important;
  color: #fff !important;
  font-size: 0.8rem !important;
  margin-right: 5px !important;
  position: relative;
  padding: 4px;
}
.custom-input-border app-multi-select-tag-search .ng-select .ng-select-container .ng-value-container,
.custom-input-border app-multi-select-tags .ng-select .ng-select-container .ng-value-container,
.custom-input-border app-multiple-age-group .ng-select .ng-select-container .ng-value-container{
  display: flex;
}
.custom-input-border .color-code-input{position: absolute;top: 3px;right: 0;}
.margin-bottom-5 {margin-bottom: 5px;}
.custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label,
.custom-input-border .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .ng-value-label{padding: 2px 0;padding-bottom: 2px;margin: 0;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;max-width: 85%;display: block; }
.custom-input-border span.ng-clear, .custom-input-border .ng-clear-wrapper{margin: 0px 0 0 0;height: auto;}
.custom-input-border .ng-select.ng-select-single.ng-invalid,
.custom-input-border .ng-select.ng-select-multiple.ng-invalid{background: none;padding-right: 0;}
.custom-input-border .custom-font-color input + label {font-size: calc(7px + 2 * (100vw - 320px) / 680) !important;}
.custom-input-border .custom-font-color.custom-checkbox .custom-control-label::before,
.custom-input-border .custom-font-color.custom-checkbox .custom-control-label::after, 
.custom-input-border .custom-font-color.custom-radio .custom-control-label::before, 
.custom-input-border .custom-font-color.custom-radio .custom-control-label::after{width: 15px;height: 15px;}
.custom-input-border .ng-value.ng-star-inserted+.ng-value.ng-star-inserted span.ng-value-label.ng-star-inserted{/*display: none;*/}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input[aria-expanded="true"]{background: #ffffff;position: relative;z-index: 5;}
.ng-select.ng-select-single .ng-select-container{height: auto !important;}
.mtop-5{margin-top: 5px;}
.custom-input-border .ng-select .ng-select-container .ng-value-container,.custom-input-border .ng-select .ng-select-container .ng-value-container{padding-left: 1rem !important;}
app-user-form .custom-input-border .ng-select .ng-select-container .ng-value-container,app-user-form .custom-input-border .ng-select .ng-select-container .ng-value-container{padding-left: 0.3rem !important;line-height: 1.2 !important;}
.custom-input-border .ng-select .ng-select-container .ng-value-container .ng-placeholder{padding-left: 0 !important;}
app-user-form .custom-input-border .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value,
app-user-form .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
app-user-form .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input,
app-user-form .custom-input-border .ng-select .ng-select-container .ng-value-container .ng-placeholder{padding-left: 0.7rem !important;}
.custom-input-border .ng-select .ng-select-container:hover{box-shadow:none}
.custom-input-border .custom-file-input{height: 2.3rem;}
.custom-input-border.form-control.is-invalid{background-position: calc(100% - 10px) 11px !important;}
.custom-input-border.form-control.is-invalid~.resource-plus-icon a.ng-star-inserted.plus-icon,.custom-input-border.form-control.is-invalid .add-video-icon{right: 42px !important;}
.select-banner-checkbox input+label{border: 1px solid #d8d6de;border-radius: 5px; width: 60px;height: 38px; text-align: center;margin-right: 10px;cursor: pointer;display: flex;align-items: center;justify-content: center;}
.select-banner-checkbox input:checked+label{border-color: #1498C7;}
.select-banner-checkbox input:checked+label svg g,.select-banner-checkbox input:checked+label svg g line{stroke: #1498C7;}
.lable-checkbox label::before, .lable-checkbox label::after{pointer-events: none;}
/*  Larger Screen Styling */
p.tab-module-text {margin: 0;line-height: 14px;word-break: break-word;}
div.badge.badge-pill.badge-light-danger:empty{display: none;}
.no-data {text-align: center;max-width: 180px;}
.no-data-image img {max-width: 100px;}
.no-data-text {font-size: 16px;margin-top: 10px;}
.cross-icon-box {position: absolute;top: -8px;right: -12px;}
.cross-icon-box a {background-color: #E0DEE3;border-radius: 50%;padding: 3px 4px;}
.image-upload-box fieldset label {min-height: 110px;height: 100%;text-align: center;max-width: 200px;margin: auto;display: flex;align-items: center;justify-content: center;padding: 10px;cursor: pointer;margin-bottom: 15px;}
.image-upload-box.form-group > div {height: calc(100% - 54px);min-height: auto;    margin-bottom: 5px;}
.image-upload-box .file-drop-zone{height: 100%;line-height: 1.5;border: dashed 2px #d8d6de;background-color:#f7f7f7!important;color:#82868B;font-size: 14px !important;text-transform: unset !important;border-radius: 7px;}
.image-upload-box  .file-drop-zone:after{display: none;}
.image-upload-box .custom-file {height: auto;}
.image-upload-box fieldset label span {color: #0295C9;font-weight: 500;}
.image-upload-box.form-group .invalid-form {line-height: normal;height: auto !important;}
.image-upload-box.form-group .invalid-form small {display: block;}
.box-height-100{height: 100%;}
.image-positoin img {position: absolute;top: 10px;left: 10px;width: 100% !important;max-width: calc(100% - 20px) !important;max-height: calc(100% - 20px) !important;}
.form-control.custom-input-border{padding: 0;padding-top: 8px;height: auto;}
.custom-input-border input[type="text"]{background: transparent;}
.image-box-height-set {max-height: calc(100vh - 290px);overflow-y: auto;overflow-x: hidden;}


.hide{display: none;}
.main-menu{z-index: 1101;}
.header-navbar{z-index: 1020;}
.ng-select.ng-select-focused .ng-select-container{z-index: 1060 !important;}
.bonus-checkbox .custom-checkbox .custom-control-label::before, .bonus-checkbox .custom-checkbox .custom-control-label::after, .bonus-checkbox .custom-radio .custom-control-label::before, .bonus-checkbox .custom-radio .custom-control-label::after{border-radius: 50%;}
.course-list-itme-text{margin-bottom: 8px;word-break: break-word;font-weight: 600;}
p.course-list-itme-text {font-size: 18px;}
.course-list-itme-text>p{font-size: 16px;}
.badge.badge-pill~.badge.badge-pill {margin-left: 5px;}
.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-box {background-color: transparent !important;box-shadow: none !important;}
.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {  padding-right: 10px;}
.bs-stepper .bs-stepper-header .step {background-color: #E9E9E9;border-radius: 5px;position: relative;min-width: 170px;border: 2px solid transparent;}
.bs-stepper .bs-stepper-header .step .step-trigger {justify-content: flex-start;padding: 8px 0px !important;}
.bs-stepper .bs-stepper-header .step.active {/*background-color: #fff;box-shadow: 0 3px 6px rgba(2, 49, 201, 0.3);border-bottom: 2px solid #0295C9;border-radius: 5px 5px 0 0;*/background-color: #ffffff !important;box-shadow: 0 2px 8px rgb(24 180 227 / 84%);border-bottom: 2px solid #0295C9;border-radius: 5px 5px 0 0;border: 2px solid #0097c6 !important;}
.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-label .bs-stepper-subtitle{color: #0295C9;}
.bs-stepper .bs-stepper-header .step.active:after {content: "";position: absolute;bottom: -10px;transform: translateX(-50%);left: 50%;border-left: 15px solid transparent;border-right: 15px solid transparent;border-top: 8px solid #229eca;}
.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-box svg path{fill: #0295C9;}
.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label{margin-left: 0;}
swiper {
  width: 100% !important;
}
.dashboard-slider swiper>.swiper.s-wrapper .swiper-wrapper .swiper-slide{max-width: 100% !important;}
swiper>.swiper.s-wrapper .swiper-wrapper .swiper-slide{max-width: fit-content !important;overflow: unset !important; margin-bottom: 10px !important; padding-right: 15px;}
.dashboard-slider swiper>.swiper.s-wrapper .swiper-wrapper .swiper-slide{padding-right: 0;}
.swiper-button-next, .swiper-button-prev {width: 30px !important;height: 30px !important;right: 0px;background-color: #fff;border-radius: 50%;box-shadow: 0 3px 6px rgb(0 0 0 / 16%);}
.swiper-button-next:after, .swiper-button-prev:after {font-size: 20px !important;}
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {opacity: 0 !important;}
.swiper-button-prev{left: 0 !important;}
.swiper-button-next{right: 0 !important;}
.swiper-top-sticky {position: sticky;top: 65px;z-index: 99;background: #f4f4f4;}
.custom-input-search {position: relative;}
.custom-search-icon-box {position: absolute;top: 2px;right: 1px;height: calc(100% - 3px);display: flex;pointer-events: none;z-index: 2;background-color: #fff;border-radius: 5px;}
.custom-input-search span.ng-arrow-wrapper {opacity: 0;}
.custom-search-icon-box span {font-size: 11px;min-width: 30px;text-align: center;height: 100%;display: flex;align-items: center;justify-content: center;height: 100%;}
.custom-input-search .custom-file-input:lang(en) ~ .custom-file-label::after {opacity: 0;}
.custom-search-icon-box span.sample-file-download a{pointer-events: fill;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {background-image: url(assets/images/ico/check.svg);top: 1px;}

.resetfilter-box {width: 50px;height: 38px;border: 1.5px solid #d8d6de;color: #8B8895;border-radius: 5px;margin-left: 10px;}
.resetfilter-box a {display: block;width: 100%;text-align: center;height: 100%;padding: 8px 0;}
.right-side-device-text .device-size-text{right: 23px;}
.cdk-drop-list .item-box{border-radius: 5px !important; margin: 10px 10px 0px 10px !important;}
.input-srearch-left-icon {min-width: 100px;}
.drag-load-more{padding-top: 15px;display: none;}
.max-width .modal-dialog {
  max-width: calc(100% - 20px) !important;
}
.max-w-1600 .modal-body,.max-width .modal-body{padding: 0 !important;} 
.cdk-drag-preview{
  z-index: 99999 !important;
}
.image-upload-cross-icon {position: absolute;top: 6px;right: 24px;}
.swal2-popup {
  border-top: 5px solid #1498c7 !important;
  position: relative !important;
}
h2.swal2-title {
  margin-top: 30px;
}

.swal2-icon.swal2-warning {
  position: absolute;
  top: -70px;
  border-color: #1498c7 !important;
  color: #fff !important;
  background: #1498c7;
  left: 0;
  right: 0;
}
.swal2-popup{
  width: 25em !important;
} 
ul.user-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.user-card-icon li a{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(18px + 2 * ((100vw - 320px) / 680));
  background-color: #ffffff;
  color: #6E6B7B;
  border-radius: 50%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  transition: all 0.3s ease-in-out;
}
ul.user-card-icon li.active a,
ul.user-card-icon li a:hover{
  background-color: #0295C9;
  color: #ffffff;
  box-shadow: 0 3px 10px #0295C9;
}
ul.user-card-icon li{
  margin-right: 15px;
}
ul.user-card-icon li:last-child {
  margin-right: 0;
}
.custom-bg-color-primary{
  background-color: #0295C9;
}
.height100{height: 100%;}
.fix-pagination {
  position: absolute;
  left: 1.5rem;
  bottom: 15px;
  right: 1.5rem;
}

a.chevron-icon {background: #E0DEE3;width: 25px;height: 25px;font-size: 20px;display: inline-flex;align-items: center;justify-content: center;border-radius: 50%;margin-left: 5px;border: 1px solid #E0DEE3;}
a.chevron-icon:hover{background-color: #fff;}
.disabled,.disabled_pagination{ opacity: 0.5;cursor: not-allowed;}
.disabled a.chevron-icon:hover,.disabled_pagination a.chevron-icon:hover{background: #E0DEE3;cursor: not-allowed;}
.pagination{margin: 0;}
.card-body.height100 {margin-bottom: 30px;}
.card-number {margin-right: 10px;position: absolute;top: -8px;left: -12px;}
.cdk-drop-list.item-list .d-flex.height100 {height: calc(100% - 70px);min-height: 200px;width: 100%;}
.d-flex.justify-content-end.flex-wrap button{margin-bottom: 10px;}
input.ng2-flatpickr-input.flatpickr-input.flatpickr-mobile {
  width: 100%;
  height: 36px;
  border: 0;
  border-radius: 8px;
  padding: 0 1rem 0 15px;
  color: #6e6b7b;
  outline: none;
}

.modal-higiht-532{
  min-height: 445px;
}
.swal2-styled.swal2-confirm:focus{
  box-shadow: none;
}

.custom-input-border span.col.text-right.text-warning {
  display: block;
  margin: 0;
  padding: 0 5px;
  color: #6e6b7b;
}

// chating css start

.chating-left-side .messge.reply-selected {
    background-color: rgb(0 156 196 / 20%) !important;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
}

// chating css end
.card-accordian-design .card{
  border: 1px solid #d8d6de;
  
}
.card-accordian-design .card-header{
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 0.428rem;
}
a.ng-star-inserted.edit-icon {
  position: absolute;
  top: 0;
  right: 10px;
  background: #E0DEE3;
  color: #8A8798;
  width: 24px;
  height: 24px;
  padding: 3px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
}
.navigation{font-size: 1rem;font-family: "Roboto", sans-serif;}
// dashboard css start

.filter-popup{
  position: absolute;
  left: 25px;
  right: 25px;
  top: 65px;
  z-index: 999;
}
.filter-box ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px !important;
  position: relative;
}
.filter-box ul li {
  width: 100%;
  padding: 0 5px !important;
}
.cross-icon a {
  font-size: 20px;
  color: #AAA6BB;
}
.box-shadow-none{box-shadow: none;}
.c-primary{color: #0295C9;}
// dashboard css end

/*TEMP*/
.modal .modal-header{padding: 0;height: 0;}
.modal .modal-header .close{transform: translate(-2px, 2px);z-index: 5;}
.modal .modal-header .close:hover, .modal .modal-header .close:focus, .modal .modal-header .close:active{transform: translate(-8px, -3px);}
.modal-body .card{margin-bottom: 0;}
/*TEMP*/
.form-control.custom-input-border select.form-control{background-position: calc(100% - 12px) 9px, calc(100% - 20px) 16px, 100% 0;}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-placeholder {
  z-index: 1;
  color: #bdbbc2 !important;
  opacity: 1;
  padding-bottom: 5px;
  top: 8px !important;
  position: absolute;
  z-index: 1;
  max-width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}
.custom-filter--select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.user-fliter--div .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-placeholder{font-size: 1rem;color: #6e6b7b !important;}
.form-control:focus:valid, .form-control:focus.is-valid{box-shadow: none;}
button.exchange-icon{cursor: default !important;}

html ngb-modal-backdrop.modal-backdrop:nth-of-type(2), body ngb-modal-backdrop.modal-backdrop:nth-of-type(2) {z-index: 1760 !important;background: rgba(34, 41, 47, 1);opacity: 0.7;}
html ngb-modal-window.modal:nth-of-type(2), body ngb-modal-window.modal:nth-of-type(2){z-index: 1760 !important;}
html ngb-modal-backdrop.modal-backdrop:nth-of-type(3), body ngb-modal-backdrop.modal-backdrop:nth-of-type(3) {z-index: 1770 !important;background: rgba(34, 41, 47, 1);opacity: 0.7;}
html ngb-modal-window.modal:nth-of-type(3), body ngb-modal-window.modal:nth-of-type(3){z-index: 1770 !important;}
html ngb-modal-backdrop.modal-backdrop:nth-of-type(4), body ngb-modal-backdrop.modal-backdrop:nth-of-type(4) {z-index: 1780 !important;background: rgba(34, 41, 47, 1);opacity: 0.7;}
html ngb-modal-window.modal:nth-of-type(3), body ngb-modal-window.modal:nth-of-type(3){z-index: 1780 !important;}
html ngb-modal-backdrop.modal-backdrop:nth-of-type(5), body ngb-modal-backdrop.modal-backdrop:nth-of-type(5) {z-index: 1790 !important;background: rgba(34, 41, 47, 1);opacity: 0.7;}
html ngb-modal-window.modal:nth-of-type(3), body ngb-modal-window.modal:nth-of-type(3){z-index: 1790 !important;}
.ump-full-width.featured-image-modal.modal.user-profile-modal{max-height: inherit !important;min-height: auto !important;max-width: 100%;margin: 0px auto;}
.scroll-to-top{bottom: 1%;}
.ng-select.ng-select-multiple .ng-option{position: relative;padding-left: 35px !important;}
.ng-select.ng-select-multiple .ng-option::before {
  content: "";
  width: 18px;
  height: 18px;
  background: white;
  position: absolute;
  left: 10px;
  border: 1px solid #7e7e7e;
  top: 9px;
  border-radius: 2px;
}

app-dashboard .ng-select.ng-select-multiple .ng-optgroup.ng-option-selected::before,
.ng-select.ng-select-multiple .ng-option.ng-option-selected::before {
  content: "✓";
  color: black;
  text-align: center;
  font-weight: 900;
}
.image-upload-box .custom-file .drozone-selector>div{
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-upload-box .custom-file .drozone-selector img{
vertical-align: middle;
margin: 0 auto;
object-fit: contain;

// position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    max-width: 100%;
    max-height: 100%;
}
.ng-select .ng-arrow-wrapper .ng-arrow{display: block !important; margin-top: -3.5px;}
.custom-input-search ng-select.ng-select-searchable {
  width: calc(100% - 35px);
  .ng-clear-wrapper{height: 18px ;}
}
.card.card-fullscreen{z-index: 1600;}
.modal{z-index: 1700;}
.modal-backdrop.show{z-index: 1650 !important;}
.featured-image-modal .modal-header {padding-top: 0 !important;}
.featured-image-modal .modal-body .card-body{padding: 1rem 0 0 0 !important;}
.modal.featured-image-modal .modal-dialog.modal-dialog-centered.modal-dialog-scrollable{height: auto; max-height: 100%;}
app-image-gallery{height: 100%;}
app-image-gallery .modal-body{height: 100%;}
app-image-gallery .modal-body .users-list-filter{overflow-x: hidden;}
app-image-gallery .modal-body .users-list-filter ngb-tooltop-window .tooltip-inner{width: 100px !important;}
.custom-position-btn ngb-tooltip-window .tooltip-inner {min-width: 180px !important; width: 100%;}
.advanc-filter-tooltip .tooltip-inner {max-width: 300px;}
.custom-position-btn {position: relative;display: flex;justify-content: center;width: 100%;margin: -35px auto -35px -25px !important;z-index: 1;height: 100%;}
.custom-position-btn+.custom-position-btn{margin: -35px auto -35px 35px !important;}
.users-list-filter .row {align-items: center;}
ngb-tooltip-window .tooltip-inner {z-index: 10;}
.tooltip{z-index: 1103;}
.modal.featured-image-modal .modal-header{height: inherit;}
.ump-full-width.user-profile-modal .modal-header{align-items: center;}
.ump-full-width.user-profile-modal.modal .modal-header .close{transform: translate(0);margin: 0 15px 0;color: #262626;}

.was-validated .form-control:invalid , .form-control.is-invalid{padding-right: 0 !important;}
.was-validated .form-control:invalid label.underline, .form-control.is-invalid label.underline {padding-right: 2rem !important;margin-left: -3px;}
.avatar [class*=avatar-status-] {width: 18px;height: 18px;bottom: -4px;}
.avatar [class*=avatar-status-]::before {content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");}
body{color: #323038;}
.dropdown-menu .dropdown-item label.custom-control-label {cursor: pointer;}

.custom-page-count {display: none;}
li.nev-item span.form-control.uneditable-input {display: none;}
body .ngx-datatable{margin-bottom: 0 !important;padding-bottom: 0rem ;}
body .ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label{line-height: 16px;}
body .ngx-datatable.bootstrap.core-bootstrap .datatable-header{height: 50px !important;}
body .ngx-datatable.bootstrap.core-bootstrap .datatable-header .datatable-header-inner{height: 100%;}
button.draggable {position: absolute;left: 0;bottom: 0;top: 0;right: 30px;width: calc(100% - 30px);opacity: 0;z-index: 5;cursor: move;}
.ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper{width: 100%;}
.ngx-datatable.bootstrap.core-bootstrap .datatable-header .datatable-header-cell-template-wrap{justify-content: center;height: 100%;}
.card .ngx-datatable.bootstrap.core-bootstrap .datatable-footer{margin-bottom: 1rem !important;padding-bottom: 0 !important;}
.flatpickr-calendar .flatpickr-day.flatpickr-disabled, .flatpickr-calendar .flatpickr-day.prevMonthDay, .flatpickr-calendar .flatpickr-day.nextMonthDay{color: #a7a7a7 !important;}
.btn{padding-left: 1.3rem;padding-right: 1.3rem;}
.modal-how-to-use .modal-body {position: relative;padding: 8px 8px 2px 8px;}
.modal-how-to-use .modal-header {padding: 0.8rem 1.4rem;height: auto;background: #EEEEEE;}
.modal-how-to-use .modal-how-to-use-close {display: block;padding: 0;outline: 0;border: 0 none;font-size: 34px;line-height: 24px;font-weight: 300;}

.rst-btn-aligned{margin-bottom: -7px;}
.user-progress--contents .course-lists-swiper .ld-navs--button {width: 35px;height: 35px;background: #D0CECE;display: flex;align-items: center;justify-content: center;border-radius: 50%;font-size: 20px;cursor: pointer;}
.user-progress--contents .course-lists-swiper .ld-navs--button.swiper-button-disabled {opacity: 0;visibility: hidden;}
.sreach-header-btn .ng-select>.ng-select-container .ng-arrow-wrapper{opacity: 0;}
.sreach-header-btn .ng-select.ng-select-opened>.ng-select-container .ng-arrow-wrapper{opacity: 1;}
.sreach-header-btn .ng-select > .ng-select-container {min-height: 36px !important;border: 0;}
.sreach-header-btn .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{padding-left: 35px;position: relative;}
.sreach-header-btn .ng-dropdown-panel .ng-dropdown-panel-items .ng-option::after{content: "\f002";font-family: "Font Awesome 5 Pro";font-weight: 300;color: #B9B9C3;position: absolute;left: 15px;top: 50%;transform: translateY(-50%);}
.ng-select .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{padding-left: 15px;}
.ngx-datatable.bootstrap .datatable-body .empty-row{padding: 3.71rem 1rem 0;}
.cstm-input--body{padding: 0 4px 10px;}
.cstm-border--box {border: 1px solid #d8d6de;border-radius: 5px;padding: 5px 6px;}
// Show Column select box css
.show-table-data--select {position: absolute;bottom: 20px;left: calc(50% - 30px);transform: translateX(-50%);}
.show-table-data--select ng-select {min-width: 70px;height: 32px !important;}
.show-table-data--select .ng-select.ng-select-focused .ng-select-container,
.show-table-data--select ng-select .ng-select-container {min-height: 32px !important;height: 32px !important;}
.form-control.custom-input-border .ng-select.ng-select-focused .ng-select-container {min-height: auto !important;}
.show-table-data--select>label {margin-right: 4px;color: #262626;font-weight: 400;}
button.input-group .input-group-append .input-group-text {padding: 0.438rem 0.7rem;}

.custom-input-border .ng-dropdown-header>.form-group{margin-bottom: 0;}
.custom-input-border .ng-dropdown-header > .form-group > .form-control {
  margin-bottom: 0;
  display: block;
  height: 2.714rem;
  padding: 0.438rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


@media(min-width:1200px){
  .add-age-group-modal.modal .modal-dialog{max-width: 1200px !important;}
  .navbar-floating .navbar-container:not(.main-menu-content) {padding: 0.8rem 1.4rem 0.8rem 1.9rem;}
}
@media screen and (min-width: 576px) and (max-width: 991px){
  .modal-dialog {max-width: 98% ;}
  .filter-box ul li {width: 50%;}
  .card-header-item-input{width: 48.7%;}
}
@media (min-width: 564px) {
  .daterangepicker {width: auto;}
  .daterangepicker .ranges ul {width: 140px;}
  .daterangepicker.single .ranges ul {width: 100%;}
  .daterangepicker.single .drp-calendar.left {clear: none;}
  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {float: left;}
  .daterangepicker {direction: ltr;text-align: left;}
  .daterangepicker .drp-calendar.left {clear: left;margin-right: 0;}
  .daterangepicker .drp-calendar.left .calendar-table {border-right: none;border-top-right-radius: 0;border-bottom-right-radius: 0;}
  .daterangepicker .drp-calendar.right {margin-left: 0;}
  .daterangepicker .drp-calendar.right .calendar-table {border-left: none;border-top-left-radius: 0;border-bottom-left-radius: 0;}
  .daterangepicker .drp-calendar.left .calendar-table {padding-right: 8px;}
  .daterangepicker .ranges, .daterangepicker .drp-calendar {float: left;}
  .mt-sm-23{margin-top: 23px;}
  .sreach-header-btn{width: 380px;}
}

@media (min-width: 730px) {
  .daterangepicker .ranges {width: auto;}
  .daterangepicker .ranges {float: left;}
  .daterangepicker.rtl .ranges {float: right;}
  .daterangepicker .drp-calendar.left {clear: none !important;}
  li.nev-item span.form-control.uneditable-input {display: block;}
}
@media (min-width: 768px) {
  .custom-input-border span.col.text-right.text-warning {display: inline;}
  .card-header-item-input-search{width: auto;}
  .card-header-item-input-search,.card-header-item-input{margin-top: 0px;}
  // .card-header-item-input {max-width: 30%;}
  .card-header-input-box {flex-wrap: nowrap;justify-content: end; width: calc(100% - 170px);}
  .card-header-input-box.resource{width: calc(100% - 400px);}
  .filter-box ul li {width: 33.33%;}
  .users-list-filter {position: relative;}
  .custom-page-count {display: block;position: absolute;/*top:calc(100% - 45px);*/ bottom: 28px; left: 15px;color: #323038;}
  .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .page-count{display: none;}
}
@media (min-width: 992px) {
  .card.card-height {/*height: calc(100% - 1rem);min-height: calc(100vh - 380px);;*/min-height: 100%;height: 100%;}
  
  .filter-box ul {width: calc(100% - 38px);}
  .filter-box ul li {width: 20%;}
  .filter-box ul li:last-child{position: absolute;top: 0;left: 100%;width: 38px;}
  .custom-position-btn {position: absolute;top: 6px;left: calc(66.666% - 27px);margin: auto !important;display: block;width: auto;height: auto;}
  .custom-position-btn + .custom-position-btn {top: 50%;transform: translateY(-50%);margin: auto !important;}
}
@media (min-width: 1024px) {
  .card-body.height100 {margin-bottom: 0px;}
}
.displayNone{display: none !important;}
@media (min-width: 1280px) {
  .modal-dialog.modal-dialog-centered.modal-dialog-scrollable.modal-xl {margin: 0 13%;}
}
@media (min-width: 1440px) {
  app-module-master .content-wrapper .container-xxl,
  .modal-content .content-wrapper .content-wrapper.container-xxl{max-width: 100%;}
  .custom-position-btn{left: 56.7%;}
  .content-header-title{font-size: 2.014rem;}
  .block-ui__element.card .card-title,.card h5.card-title{font-size: 1.685rem;}
}
@media screen and (min-width: 1920px) {
  .modal.max-width .modal-dialog,
  .modal.max-w-1600 .modal-dialog {max-width: 1400px !important;margin: auto;.card-header-item-input-search,.card-header-item-input{margin-top: 0px;max-width: 250px;}
}
}
@media (max-width: 1440px) {
  html .content.app-content {padding: calc(0.4rem + 4.45rem + 1.3rem) 2rem 0;}
  .content-wrapper .card .card-header{padding: 0.6rem 1.5rem !important;}
  .btn.btn-hs-icon-custom{height: 2.315rem;width: 2.315rem;}
  ul.user-card-icon li a{font-size: 18px;width: 35px;height: 35px;}
  .card-header .heading-elements a{width: 35px;height: 35px;}
  .block-ui__element.card .card-body,
  .users-list-filter .card-body{padding: .75rem 1.5rem;}
  .table-shorting-select ng-select, .table-shorting-select .ng-select .ng-select-container{height: 32px !important;min-height: 32px !important;}
  .ngx-datatable.bootstrap.core-bootstrap .datatable-body-row .datatable-body-cell{padding: 0.45rem 1rem;}
  .form-control.custom-input-border {padding-top: 4px;}
  .btn.btn-icon.btn-icon-custom{width: 32px;height: 32px;font-size: 12px;line-height: 1.4;}
  .custom-input-border .ng-select .ng-select-container .ng-value-container,
  .custom-input-border .form-control, .custom-input-border .input-group-text{height: 2.014rem;font-size:14px;}
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-placeholder{font-size: 14px;}
  .custom-input-border .ng-select .ng-select-container .ng-value-container{padding: 0.238rem 1rem 0.4rem 1rem;}
  .ngx-datatable .datatable-body-cell .datatable-body-cell-label .ellipsis-text-box, .ngx-datatable .datatable-header-cell .datatable-header-cell-label .ellipsis-text-box{line-height: 1.1;}
  .card .table-shorting-select .card-title{margin-bottom: 0.73rem;}
  .rst-btn-aligned{margin-bottom: 0px;}
  .daterangepicker .ranges li {font-size: 14px;}
  .custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.custom-input-border .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: 13px !important;
}
}
.ngx-datatable .datatable-body-cell .datatable-body-cell-label .ellipsis-text-box{display: inline-block;}
.loading-foreground {
  backdrop-filter: blur(8px);
}

ul.cursor-disable-style li.disabled a{
  cursor: not-allowed;
}
// / select dropdown changes
::ng-deep .custom-input-change-select .custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value, ::ng-deep .custom-input-change-select .custom-input-border .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value{
    background-color: transparent!important;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-text-fill-color: #333!important;
    color: #333!important;
    border: none!important;
    font-size: 1.3rem!important;
    padding-bottom: 0.2rem;
    padding-top: 2px;
    margin: 0;
    position: absolute;
    width: 85%;
}
.card-accordian-design.custom-input-change-select .custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: transparent!important;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-text-fill-color: #333!important;
  color: #333!important;
  border: none!important;
  font-size: 1.2rem!important;
  padding-bottom: 0;
  padding-top: 7px;
  margin: 0;
  position: absolute;
  width: 85%;
  font-weight: 400 !important;
}
.card-accordian-design.custom-input-change-select .ng-select-container .ng-value-container .ng-value .ng-value-label{  padding: 0 0 4px;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 85%;
  display: block;
}


::ng-deep .card-accordian-design.custom-input-change-select .custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  display: none  !important;
}

body .card-accordian-design.custom-input-change-select .custom-input-border .ng-value.ng-star-inserted+.ng-value.ng-star-inserted span.ng-value-label.ng-star-inserted {
  display: none !important;
}

.custom-input-remove-dblock.custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.custom-input-remove-dblock.custom-input-border .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  display: inline-block !important;
}
.custom-input-remove-dblock.custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  width: 100% !important;
  max-width: 100% !important;
}
body .custom-input-remove-dblock.custom-input-border .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  width: 100% !important;
  max-width: 100% !important;
}
body .custom-text-manage-select-box span.ng-star-inserted {
    padding: 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.custom-input-border .ng-dropdown-panel span.ng-value-label{
  overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.custom-input-border .ng-dropdown-panel .ng-dropdown-panel-items .ng-option>div{
  overflow: hidden;
    text-overflow: ellipsis;
    display: block; 
}
.ng-select{min-height: 32px;}
.ng-select .ng-dropdown-header>.form-group{margin-bottom: 0;}
.custom-input-border .ng-select .ng-select-container div>span{
  display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-input-border>.d-flex>.flex-fill{
  flex-basis: 100%;
    max-width: 100%;
}
.custom-input-border .ng-select .ng-select-container div{
  max-width: 100%;
    flex-basis: 100%;
}

.ng-option:has(.cors--disabled) {
  opacity: 0.7 !important;
  cursor: alias !important;
}
.cors--disabled:focus, .cors--disabled:active  {
  pointer-events: none !important;
  opacity: 0.7 !important;
}
.ng-option:has(.cors--disabled):focus, .ng-option:has(.cors--disabled):active {
  pointer-events: none !important;
  opacity: 0.7 !important;
}


.form-control:focus, .custom-input-border .ng-dropdown-header > .form-group > .form-control:focus {
  border-color: #1498c7 !important;
}
::ng-deep .edit-course--outerfilter .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 14px !important;
}



.user-fliter--div.user-fliter--custome--col .col-xs-12.col-sm-6.col-md-6.col-xl-3.col-lg-3.col-xxl-2{ flex: 0 0 20% !important;max-width: 20% !important;}
body .user-fliter--div.user-fliter--custome--col  .input-group .form-control {
  padding: 10px 5px !important;
}
::ng-deep .ng-dropdown-panel.ng-select-bottom{
  margin-top: 0px !important;
}



@media (min-width: 1280px) and (max-width: 1680px) {

.user-fliter--div.user-fliter--custome--col .col-xs-12.col-sm-6.col-md-6.col-xl-3.col-lg-3.col-xxl-2{ flex: 0 0 25% !important;max-width: 25% !important;}
}

::ng-deep .ump-full-width.featured-image-modal.modal.user-profile-modal { max-height: inherit !important;min-height: auto !important;}
.ump-full-width.featured-image-modal.modal.user-profile-modal .modal-content{
  height: 100% !important;
  border-radius: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.mastertracking-filter-row .custom-input-border .ng-select .ng-arrow{
  background: url(./assets/images/roi-drop-down.svg) center no-repeat;
  background-size: 8px;
  margin-top: -21px;
  height: 28px !important;
}
.mastertracking-filter-row .custom-input-border .ng-select .ng-select-container .ng-value-container .ng-placeholder,
.mastertracking-filter-row .custom-input-border button.input-group span{font-size: 16px; }
.dropdown-year-month .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {color: #6E6B7B !important; }
.mastertracking-filter-row .ng-clear-wrapper.ng-star-inserted {margin-top: -18px; }
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 7px 8px 3px 8px !important;
  margin-bottom: 0;
  font-size: 1rem;
  list-style: none; 
  background-color: transparent;
  border-radius: 0;
  line-height: 1.5;
}

.form-group-date-event-webinar button.input-group, .form-group-date-poll-master button.input-group{
  display: flex !important;
  align-items: center !important;
  padding: 0 0rem 0 1rem !important;
  justify-content: space-between !important;
}

.md-drppicker td.active, .md-drppicker td.active:hover{
  background-color: #1498C7 !important;
}
.md-drppicker .calendar-time .select .select-item{
  width: 30% !important;
}
.md-drppicker .btn{
  background-color: #1498C7 !important;
  line-height: 30px !important; 
}

body .form-group-date-event-webinar .ngxdaterangepicker-row{
  width: 100% !important;
  overflow: hidden !important;
  display: inline-block !important;
  text-overflow: ellipsis !important;
  padding-right: 0 !important;
  cursor: pointer !important;
  z-index: 2 !important; 
}

body .form-group-date-event-webinar .input-group-append{
  position: absolute;
  top: 50% !important;
  right: 0 !important;
  padding: 0 1px !important;
  height: 100% !important; 
  transform: translateY(-50%) !important; 
  display: flex !important;
  align-items: center !important;
  pointer-events: none !important;
  
}
body .form-group-date-event-webinar .ngxdaterangepicker-row input{
  padding-left: 0;
  cursor: pointer;
}

