// @import '@swimlane/ngx-datatable/index.css';
// @import '@swimlane/ngx-datatable/themes/bootstrap.scss';
// @import '@swimlane/ngx-datatable/assets/icons.css';


@import '@core/scss/base/colors';
@import '@core/scss/base/components/include'; // Components includes

// variable declaration
$datatables-bg-header-color: $table-head-bg;
$datatables-text-color: $gray-400;
$datatables-border-color: $border-color;
$datatables-footer-text-color: $text-muted;
$datatables-dark-text-color: $theme-dark-body-color;
$datatables-dark-footer-bg-color: $theme-dark-card-bg;
$datatables-dark-border-color: $theme-dark-border-color;
$datatables-dark-header-bg-color: $theme-dark-table-header-bg;
$datatables-dark-body-bg-color: $theme-dark-table-bg;
$datatables-dark-active-bg-color: $theme-dark-card-bg;

.ngx-datatable {
  &.bootstrap {
    &.core-bootstrap {
      &.height-70-vh {
        height: 70vh !important;
      }

      .datatable-header {
        background-color: $datatables-bg-header-color;
        text-transform: uppercase;
        height: 40px !important;
        border-top: 1px solid $datatables-border-color;

        .datatable-header-cell {
          border-bottom: 0;
          padding: 0.75rem 1rem;
          font-weight: bold;
          font-size: 0.8rem;
        }
        // sort-icon positioning
        .datatable-header-cell-template-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          letter-spacing: 1px;
        }
        .sort-btn {
          &:before {
            font-size: 1.3rem;
          }
        }
      }

      .datatable-body-row {
        border-color: $datatables-border-color;

        &.datatable-row-even {
          background-color: initial;
        }

        &.active {
          background-color: $gray-50;
          color: $body-color;
        }

        .datatable-body-cell-label {
          .cell-line-height {
            line-height: 1.25;
          }
        }

        .datatable-body-cell {
          padding: 0.75rem 1rem;
          display: flex;
          align-items: center;
        }
      }

      .datatable-footer {
        background-color: $white;
        color: $datatables-text-color;
        overflow: hidden;
        margin-bottom: 1rem;

        .datatable-pager {
          a {
            color: $datatables-text-color;
            line-height: 20px;
          }

          ul {
            li:not(.disabled) {
              &.active,
              &.active:hover {
                vertical-align: sub;
                a {
                  background-color: $primary !important;
                  color: $white !important;
                  border-radius: 50%;
                  width: 32px;
                  height: 32px;
                  padding: 5px;
                }
              }
              &:hover {
                a {
                  background-color: transparent !important;
                  color: $primary !important;
                }
              }
            }

            li {
              margin: 2px 0px 11px 0px !important;
              &:not(.disabled):hover {
                a {
                  background-color: $white;
                  color: $datatables-text-color;
                }
              }

              &.disabled {
                a {
                  color: $text-muted;
                }
              }
            }
          }
          .pager {
            background-color: $pagination-bg;
            border-top-left-radius: 28px;
            border-bottom-left-radius: 28px;
            border-top-right-radius: 28px;
            border-bottom-right-radius: 28px;
            height: 36px;
          }
        }
        .page-count {
          color: $datatables-footer-text-color;
        }
      }

      &.header-height-50 {
        .datatable-header {
          height: 50px !important;
        }
      }
      .datatable-body-cell {
        overflow: visible !important;
      }
    }
  }
}

// media query for Small & Medium devices
@media (max-width: 768px) {
  .page-count {
    display: none;
  }

  .datatable-pager {
    text-align: center !important;
    margin: 0 !important;
  }
}

@media screen and (max-width: 800px) {
  .desktop-hidden {
    display: initial;
  }

  .mobile-hidden {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  .desktop-hidden {
    display: none;
  }

  .mobile-hidden {
    display: initial;
  }
}

// dark layout style
.dark-layout {
  .datatable-header-inner,
  .datatable-header {
    background-color: $datatables-dark-header-bg-color !important;
    border-top: 1px solid $datatables-dark-border-color !important;
  }

  .ngx-datatable.bootstrap.core-bootstrap {
    .datatable-footer {
      background-color: $datatables-dark-footer-bg-color !important;
      .datatable-pager ul li.disabled a {
        color: $datatables-text-color;
      }
      .datatable-pager a {
        color: $datatables-dark-text-color;
      }
    }
  }

  .datatable-body-row {
    border-color: $datatables-dark-border-color !important;
    &.active {
      background-color: $datatables-dark-active-bg-color !important;
      color: inherit !important;
    }
  }

  .datatable-body {
    background-color: $datatables-dark-body-bg-color !important;
  }

  .pager {
    background-color: $theme-dark-pagination-bg !important;
  }
}




.ngx-datatable {
  display: block;
  overflow: hidden;
  justify-content: center;
  position: relative;
  transform: translate3d(0, 0, 0);
  /**
   * Vertical Scrolling Adjustments
   */
  /**
   * Horizontal Scrolling Adjustments
   */
  /**
   * Fixed Header Height Adjustments
   */
  /**
   * Fixed row height adjustments
   */
  /**
   * Shared Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */ }
  .ngx-datatable [hidden] {
    display: none !important; }
  .ngx-datatable *,
  .ngx-datatable *:before,
  .ngx-datatable *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .ngx-datatable.scroll-vertical .datatable-body {
    overflow-y: auto; }
  .ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
    position: absolute; }
  .ngx-datatable.scroll-horz .datatable-body {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
    white-space: nowrap; }
    .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .ngx-datatable.fixed-row .datatable-scroll {
    white-space: nowrap; }
    .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
      white-space: nowrap; }
      .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
  .ngx-datatable .datatable-body-row,
  .ngx-datatable .datatable-row-center,
  .ngx-datatable .datatable-header-inner {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    -webkit-flex-flow: row;
    -moz-flex-flow: row;
    -ms-flex-flow: row;
    -o-flex-flow: row;
    flex-flow: row; }
  .ngx-datatable .datatable-body-cell,
  .ngx-datatable .datatable-header-cell {
    overflow-x: hidden;
    vertical-align: top;
    display: inline-block;
    line-height: 1.625; }
    .ngx-datatable .datatable-body-cell:focus,
    .ngx-datatable .datatable-header-cell:focus {
      outline: none; }
  .ngx-datatable .datatable-row-left,
  .ngx-datatable .datatable-row-right {
    z-index: 9; }
  .ngx-datatable .datatable-row-left,
  .ngx-datatable .datatable-row-center,
  .ngx-datatable .datatable-row-group,
  .ngx-datatable .datatable-row-right {
    position: relative; }
  .ngx-datatable .datatable-header {
    display: block;
    overflow: hidden; }
    .ngx-datatable .datatable-header .datatable-header-inner {
      align-items: stretch;
      -webkit-align-items: stretch; }
    .ngx-datatable .datatable-header .datatable-header-cell {
      position: relative;
      display: inline-block; }
      .ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
        cursor: pointer; }
      .ngx-datatable .datatable-header .datatable-header-cell.longpress .datatable-header-cell-wrapper {
        cursor: move; }
      .ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
        line-height: 100%;
        vertical-align: middle;
        display: inline-block;
        cursor: pointer; }
      .ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
      .ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        padding: 0 4px;
        visibility: hidden; }
      .ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
        cursor: ew-resize; }
      .ngx-datatable .datatable-header .datatable-header-cell.resizeable:hover .resize-handle {
        visibility: visible; }
      .ngx-datatable .datatable-header .datatable-header-cell:hover .resize-handle--not-resizable {
        visibility: visible; }
      .ngx-datatable .datatable-header .datatable-header-cell .targetMarker {
        position: absolute;
        top: 0;
        bottom: 0; }
        .ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromLeft {
          right: 0; }
        .ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromRight {
          left: 0; }
      .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
        height: inherit; }
  .ngx-datatable .datatable-body {
    position: relative;
    z-index: 10;
    display: block; }
    .ngx-datatable .datatable-body .datatable-scroll {
      display: inline-block; }
    .ngx-datatable .datatable-body .datatable-row-detail {
      overflow-y: hidden; }
    .ngx-datatable .datatable-body .datatable-row-wrapper {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
    .ngx-datatable .datatable-body .datatable-body-row {
      outline: none; }
      .ngx-datatable .datatable-body .datatable-body-row > div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex; }
  .ngx-datatable .datatable-footer {
    display: block;
    width: 100%;
    overflow: auto; }
    .ngx-datatable .datatable-footer .datatable-footer-inner {
      display: flex;
      align-items: center;
      width: 100%; }
    .ngx-datatable .datatable-footer .selected-count .page-count {
      flex: 1 1 40%; }
    .ngx-datatable .datatable-footer .selected-count .datatable-pager {
      flex: 1 1 60%; }
    .ngx-datatable .datatable-footer .page-count {
      flex: 1 1 20%; }
    .ngx-datatable .datatable-footer .datatable-pager {
      flex: 1 1 80%;
      text-align: right; }
      .ngx-datatable .datatable-footer .datatable-pager .pager,
      .ngx-datatable .datatable-footer .datatable-pager .pager li {
        padding: 0;
        margin: 0;
        display: inline-block;
        list-style: none; }
      .ngx-datatable .datatable-footer .datatable-pager .pager li,
      .ngx-datatable .datatable-footer .datatable-pager .pager li a {
        outline: none; }
      .ngx-datatable .datatable-footer .datatable-pager .pager li a {
        cursor: pointer;
        display: inline-block; }
      .ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
        cursor: not-allowed; }






        /*
bootstrap table theme
*/

.ngx-datatable.bootstrap {
  box-shadow: none;
  font-size: 13px;
  .datatable-header {
    height: unset !important;
    .datatable-header-cell {
      vertical-align: bottom;
      padding: 0.75rem;
      border-bottom: 1px solid #d1d4d7;
      .datatable-header-cell-label {
        line-height: 24px;
      }
    }
  }
  .datatable-body {
    .datatable-body-row {
      vertical-align: top;
      border-top: 1px solid #d1d4d7;
      &.datatable-row-even {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &.active {
        background-color: #1483ff;
        color: #fff;
      }
      .datatable-body-cell {
        padding: 0.75rem;
        text-align: left;
        vertical-align: top;
      }
    }
    .empty-row {
      $alert-padding-y: 0.75rem !default;
      $alert-padding-x: 1.25rem !default;
      position: relative;
      padding: $alert-padding-y $alert-padding-x;
      margin-bottom: 0;
    }
  }
  .datatable-footer {
    background: #424242;
    color: #ededed;
    margin-top: -1px;
    .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem;
    }
    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;
      ul {
        li {
          margin: 10px 0px;
          &:not(.disabled) {
            &.active,
            &:hover {
              a {
                background-color: #545454;
                font-weight: bold;
              }
            }
          }
        }
      }
      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;
        color: #ededed;
      }
      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }
  // Summary row styles
  .datatable-summary-row {
    .datatable-body-row {
      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
}








/* icon */

// @charset "UTF-8";

@font-face {
  font-family: 'data-table';
  src: url('../../../../assets/fonts/swimlane-fonts/data-table.eot');
  src: url('../../../../assets/fonts/swimlane-fonts/data-table.eot?#iefix') format('embedded-opentype'), url('../../../../assets/fonts/swimlane-fonts/data-table.woff') format('woff'),
    url('../../../../assets/fonts/swimlane-fonts/data-table.ttf') format('truetype'), url('../../../../assets/fonts/swimlane-fonts/data-table.svg#data-table') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]::before {
  font-family: 'data-table' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='datatable-icon-']::before,
[class*=' datatable-icon-']::before {
  font-family: 'data-table' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.datatable-icon-filter::before {
  content: '\62';
}

.datatable-icon-collapse::before {
  content: '\61';
}

.datatable-icon-expand::before {
  content: '\63';
}

.datatable-icon-close::before {
  content: '\64';
}

.datatable-icon-up::before {
  content: '\65';
}

.datatable-icon-down::before {
  content: '\66';
}

.datatable-icon-sort-unset::before {
  content: '\63';
  opacity: 0.5;
}

.datatable-icon-sort::before {
  content: '\67';
}

.datatable-icon-done::before {
  content: '\68';
}

.datatable-icon-done-all::before {
  content: '\69';
}

.datatable-icon-search::before {
  content: '\6a';
}

.datatable-icon-pin::before {
  content: '\6b';
}

.datatable-icon-add::before {
  content: '\6d';
}

.datatable-icon-left::before {
  content: '\6f';
}

.datatable-icon-right::before {
  content: '\70';
}

.datatable-icon-skip::before {
  content: '\71';
}

.datatable-icon-prev::before {
  content: '\72';
}
